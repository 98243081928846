@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

/* Main container for admin panel */
.admin-panel {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

/* Container for table to allow horizontal scrolling on small screens */
.admin-table-container {
  overflow-x: auto;
}

/* Table styles */
.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  font-size: 0.9em;
  background-color: #fafafa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* Table head styles */
.admin-table thead tr {
  background-color: #263238;
  color: #ffffff;
  text-align: left;
}

/* Table header and cells styles */
.admin-table th,
.admin-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

/* Zebra striping for table rows */
.admin-table tbody tr:nth-child(even) {
  background-color: #f4f4f4;
}

/* Download button container */
.pdf-download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px; /* Spacing between rows */
}

/* PDF name styles */
.pdf-name {
  flex-grow: 1; /* Allows the name to take up the available horizontal space */
}

/* Download button styles */
.pdf-download button {
  padding: 8px 16px;
  background-color: #546e7a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.8rem;
  white-space: nowrap;
  margin-left: 16px; /* Space from the PDF name */
}

/* Hover effect for download buttons */
.pdf-download button:hover {
  background-color: #455a64;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
  .admin-panel {
    margin: 1rem;
    padding: 0.5rem;
  }

  .admin-table-container {
    margin: 0; /* Remove margin to use the full width */
    padding: 0; /* Remove padding for full-width table */
  }

  .admin-table {
    min-width: 600px; /* Set minimum table width */
  }

  .admin-table th,
  .admin-table td {
    padding: 8px; /* Reduce padding on smaller screens for more space */
    font-size: 0.75rem; /* Reduce font size for smaller screens */
  }

  .pdf-download {
    flex-direction: column; /* Stack PDF name and download button vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 0.5rem; /* Adjust margin for spacing */
  }

  .pdf-download button {
    padding: 6px 12px; /* Adjust padding for buttons */
    font-size: 0.7rem; /* Adjust font size for buttons */
    margin-left: 0; /* Remove margin from the left of buttons */
    margin-bottom: 0.25rem; /* Add space between stacked buttons */
  }

  /* Ensures horizontal scrolling for the table on smaller screens */
  .admin-table-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  /* Adjust the layout of cells to ensure data is not cut off */
  .admin-table td {
    position: relative;
  }
}

/* Styles for login container */

.login-main {
  height: 100vh !important;
  display: flex;
  align-items: center;
}

.login-container {
  width: 100%;
  max-width: 340px;
  margin: auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.login-container h2 {
  margin-top: 0;
  color: #333;
}

.login-container input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.2s;
}

.login-container button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 10px;
}

/* Responsive styles for mobile screens */
@media screen and (max-width: 768px) {
  .login-container {
    padding: 1rem;
    margin: 20px;
  }
}

/* Responsive styles for mobile screens */
@media screen and (max-width: 768px) {
  .admin-table-container {
    margin: 10px;
    padding: 10px;
  }

  .admin-table,
  .admin-table th,
  .admin-table td {
    padding: 8px;
    font-size: 0.8rem;
  }
}
